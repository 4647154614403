import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled, { createGlobalStyle } from 'styled-components'
import { DummyGiftCardVertical } from '@components/atoms/cards/dummies/DummyGiftCardVertical'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { Fonts } from '@components/atoms/typography/Fonts'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { colours } from '@configs/colours'
import { zIndexes } from '@constants/zIndexes'

import { Icons } from '@typeDeclarations/components/atoms/icons'
import { mediaQueries } from '@utils/mediaQueries'
import { TitleHeaderH1SCSS } from '@components/atoms/typography/css'
import { api } from '@services/api'
import { PersonalizationPreview, ProductDefaultPreview } from '@services/api.types'
import GeneralLayout from '@layouts/general/layout'
import { BrandHeaderStateless } from '@components/atoms/BrandHeaderStateless/BrandHeaderStateless'
import { BrandMobileHeaderStateless } from '@components/atoms/BrandMobileHeaderStateless/BrandMobileHeaderStateless'

const ConfigPreview: React.FC = () => {
  const { i18n, t } = useTranslation()
  const [searchParams] = useSearchParams()

  const [personalizationPreview, setPersonalizationPreview] = useState<PersonalizationPreview>()
  const [productPersonalization, setProductPersonalization] = useState<{
    product_image_url: string
  }>()
  const [productDefaultPreview, setProductDefaultPreview] = useState<ProductDefaultPreview>()


  const language = i18n.language
  const welcomeOverlay = searchParams.get('overlay') === 'welcome'
  const chosenLanguage = personalizationPreview?.language_specifics?.find(
    (l) => l.language === language,
  )

  const title = chosenLanguage?.welcome_title
  const text = chosenLanguage?.welcome_description

  const welcomeImage = personalizationPreview?.image_welcome_url

  useEffect(() => {
    const productPublicKey = searchParams.get('product-id')
    const organizationId = searchParams.get('organization-id')
    const personalizationId = searchParams.get('personalization-id')

    if (productPublicKey) {
      api.productDefaultPreview(productPublicKey).then((p) => setProductDefaultPreview(p))
    }

    if (organizationId) {
      api.shopPersonalizationPreview(organizationId).then((p) => setPersonalizationPreview(p))
    }

    if (personalizationId) {
      api.productPersonalization(personalizationId).then((p) => setProductPersonalization(p))
    }
  }, [])

  return (
    <GeneralLayout
      standalone
      imageLogo={personalizationPreview?.image_logo_url}
      brandColor={personalizationPreview?.color_bar}
    >
      <TrengoWidgetModifier />
      <Root>
        <BackgroundShop>
          <Wrapper>
            <BrandHeaderStateless
              colorBar={personalizationPreview?.color_bar ?? ''}
              currency="EUR"
              debitValue={10}
              shopBannerType={personalizationPreview?.shop_banner}
              shopText={chosenLanguage?.shop_text}
              slotImage={
                productPersonalization?.product_image_url ??
                personalizationPreview?.image_choicecard_url ??
                productDefaultPreview?.primary_image
              }
            />
            <BrandMobileHeaderStateless
              noBalance={false}
              currency='EUR'
              slotImage={
                personalizationPreview?.image_choicecard_url ??
                productDefaultPreview?.primary_image
              }
              debitValue={10}
            />
            <WidthBoundary>
              <Title>Lorem ipsum</Title>
              <Grid>
                {Array.from({ length: 12 }, (_, i) => i).map((el) => (
                  <DummyGiftCardVertical key={el} />
                ))}
              </Grid>
            </WidthBoundary>
          </Wrapper>
        </BackgroundShop>
        {welcomeOverlay && (
          <Section>
            <Overlay>
              <BrandImageWrapper $backgroundColor={colours.prisma.white}>
                {welcomeImage && <Img alt="Welcome logo" className="logo" src={welcomeImage} />}
              </BrandImageWrapper>
              <Content>
                <div>
                  <Greeting>{title ?? t('hiThere')}</Greeting>
                  <Subtitle>{text ?? t('welcomeSubtitle')}</Subtitle>
                </div>
                <StyledPrimaryButton iconRight={Icons.ArrowRight} type="submit">
                  {t('explore')}
                </StyledPrimaryButton>
              </Content>
            </Overlay>
          </Section>
        )}
      </Root>
    </GeneralLayout>
  )
}

const TrengoWidgetModifier = createGlobalStyle`
  div.TrengoWidgetLauncher__iframe {
    display: none;
  }
`

const BackgroundShop = styled.div`
  ${mediaQueries.from.breakpoint.desktop} {
    height: 0;
    max-height: 0;
  }
`

const Section = styled.section`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  top: 0;
  left: 0;
  z-index: ${zIndexes.blurOverlay};
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  ${mediaQueries.from.breakpoint.desktop} {
    overflow: hidden;
    justify-content: flex-start;
    align-items: center;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  ${mediaQueries.to.breakpoint.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${mediaQueries.to.breakpoint.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mediaQueries.to.breakpoint.mobileL} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  border-radius: 24px;
  ${mediaQueries.from.breakpoint.desktop} {
    flex-direction: row;
    width: 800px;
    height: 440px;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
`

const BrandImageWrapper = styled.div<{ $backgroundColor?: Maybe<string> }>`
  width: 100%;
  max-height: 440px;
  display: flex;
  justify-content: center;
  background-color: ${(p) => (p.$backgroundColor ? p.$backgroundColor : colours.prisma.white)};
  background-image: url(Shop);
  overflow: hidden;
  ${mediaQueries.from.breakpoint.desktop} {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    flex: 50% 1 1;
    box-sizing: border-box;
  }
`

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const Content = styled.div`
  flex-grow: 10;
  background-color: white;
  border-radius: 24px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px max(calc((100% / 2) - calc(1200px / 2)), 24px);
  ${mediaQueries.from.breakpoint.desktop} {
    padding: 48px 32px 32px 40px;
    flex: 50% 1 1;
    box-sizing: border-box;
  }
  ${mediaQueries.to.breakpoint.desktop} {
    border-radius: 0;
  }
`

const Greeting = styled.div`
  ${TitleHeaderH1SCSS};
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 2ch;
`

const Subtitle = styled(Fonts.BodyLarge)`
  color: ${colours.mist[800]};
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 24px;
  cursor: not-allowed;
`

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 24px;
  margin: 12px 0;
`

const Wrapper = styled.div`
  width: 100%;
  font-family: 'Poppins';
  height: 100%;
  ${mediaQueries.to.breakpoint.desktop} {
  }
`

export default ConfigPreview
