import { SERVICE_NAME } from '@configs/constants'

export const manualLanguageSetKey = `${SERVICE_NAME}_language_manually_changed`

export enum LanguageCodes {
  De = 'de',
  Da = 'da',
  Et = 'et',
  EnGB = 'en-GB',
  EnUS = 'en-US',
  Es = 'es',
  Fi = 'fi',
  Fr = 'fr',
  It = 'it',
  Lt = 'lt',
  Lv = 'lv',
  Nl = 'nl',
  No = 'no',
  Pl = 'pl',
  Sv = 'sv',
  Cs = 'cs',
  Hu = 'hu',
  Pt = 'pt',
  Ro = 'ro',
  El = 'el',
}

export type Languages = `${LanguageCodes}`
export type TestEnvLanguage = 'cimode'

export const languageCodeToName = {
  [LanguageCodes.De]: 'Deutsch',
  [LanguageCodes.Da]: 'Dansk',
  [LanguageCodes.Et]: 'Eesti',
  [LanguageCodes.EnGB]: 'English (UK)',
  [LanguageCodes.EnUS]: 'English (US)',
  [LanguageCodes.Es]: 'Español',
  [LanguageCodes.Fi]: 'Suomi',
  [LanguageCodes.Fr]: 'Français',
  [LanguageCodes.It]: 'Italiano',
  [LanguageCodes.Lt]: 'Lietuvių',
  [LanguageCodes.Lv]: 'Latviešu',
  [LanguageCodes.Nl]: 'Nederlands',
  [LanguageCodes.No]: 'Norsk',
  [LanguageCodes.Pl]: 'Polski',
  [LanguageCodes.Sv]: 'Svenska',
  [LanguageCodes.Cs]: 'Čeština',
  [LanguageCodes.Hu]: 'Magyar',
  [LanguageCodes.Pt]: 'Português',
  [LanguageCodes.Ro]: 'Română',
  [LanguageCodes.El]: 'Ελληνικά',
}
