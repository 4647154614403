import { produce } from 'immer'
import React, { PropsWithChildren, createContext, useCallback, useEffect, useState } from 'react'

import { useMainKeyContext } from '@hooks/useMainKey'
import { useSavedState } from '@hooks/useSavedState'
import { CatalogueProduct } from '@services/api.types'
import { FeePaymentOptionEnum } from '@typeDeclarations/feePaymentOption'
import { getLocalStorageKey } from '@utils/getLocalStorageKey'
import { api } from '@services/api'

export type CartItem = {
  id: string | number
  amount: number
  currency?: string
  denominatedValues?: boolean | null
  description?: string
  feeFlat?: number | null
  feePaymentOption: FeePaymentOptionEnum | null | undefined
  feePercentage?: number | null
  imageUrl?: string
  maximumQuantity?: number | null
  name?: string
  quantity: number
}

export type ChoiceCardItem = {
  claim_code: string
  debit_value: number
  name?: string
  currency?: string
}

type Carts = Partial<Record<string, Cart>>

export type Cart = {
  cards: CartItem[]
  choiceCardsToUse: ChoiceCardItem[]
  orderId?: string | null
  email?: string
}

export type AggregatedCart = {
  carts: Carts
}

export type CartContext = {
  aggregatedCart: AggregatedCart
  addCard: (item: CartItem, cartId?: string) => AggregatedCart | undefined
  addChoiceCard: (item: ChoiceCardItem, cartId?: string | null) => AggregatedCart | undefined
  clearCart: (cartId?: string) => void
  getCurrentCart: () => Cart | undefined
  getCurrentMainChoiceCard: () => ChoiceCardItem | undefined
  getOrderId: () => string | undefined | null
  isMainChoiceCardEmpty: () => boolean | undefined
  listCarts: () => Partial<Record<string, Cart>>
  checkIfChoiceCardBalancesUpToDate: (cartId?: string) => Promise<Maybe<boolean>>
  removeCart: (cartId?: string) => AggregatedCart | undefined
  resetAggregatedCart: () => AggregatedCart | undefined
  saveCart: (cart?: AggregatedCart) => void
  setAggregatedCart: (value: AggregatedCart) => void
  setCart: (item: Cart, cartId?: string) => AggregatedCart | undefined
  setMainChoiceCardBalance: (newBalance?: number | null, cartId?: string) => void
  setOrderId: (orderId?: string | null, cartId?: string) => undefined | AggregatedCart
  updateCardsLanguage: (products: CatalogueProduct[], cartId?: string) => void
}

type CartContextProvider = {
  values?: {
    cart: AggregatedCart
  }
}

const defaultAggregatedCart: AggregatedCart = { carts: {} }
const defaultCart = {
  cards: [],
  choiceCardsToUse: [],
  orderId: null,
  email: '',
}

export const CartContext = createContext<CartContext>({
  addCard: () => undefined,
  addChoiceCard: () => undefined,
  aggregatedCart: defaultAggregatedCart,
  clearCart: () => {},
  getCurrentCart: () => undefined,
  getCurrentMainChoiceCard: () => undefined,
  getOrderId: () => undefined,
  listCarts: () => ({}),
  checkIfChoiceCardBalancesUpToDate: () => Promise.resolve(undefined),
  removeCart: () => undefined,
  resetAggregatedCart: () => undefined,
  saveCart: () => {},
  setAggregatedCart: () => {},
  setCart: () => undefined,
  setMainChoiceCardBalance: () => {},
  setOrderId: () => undefined,
  updateCardsLanguage: () => {},
  isMainChoiceCardEmpty: () => undefined,
})

const isCard = (obj: unknown): obj is CartItem => {
  if (typeof obj !== 'object' || obj === null) return false

  const isCardItem = [
    { prop: 'id', expected: ['string', 'number'] },
    { prop: 'amount', expected: ['number', 'string'] }, // can be ''
    { prop: 'quantity', expected: ['number', 'string'] },
  ]
    .map(
      ({ prop, expected }) =>
        prop in obj && expected.includes(typeof obj[prop as keyof typeof obj]),
    )
    .every((v) => v)

  return isCardItem
}

const isChoiceCardItem = (obj: unknown): obj is ChoiceCardItem => {
  if (typeof obj !== 'object' || obj === null) return false

  const isChoiceCardItem = [
    { prop: 'claim_code', expected: 'string' },
    { prop: 'debit_value', expected: 'number' },
  ].map(({ prop, expected }) => {
    return prop in obj && typeof obj[prop as keyof typeof obj] === expected
  })

  return isChoiceCardItem.every((v) => v)
}

function isCart(obj: unknown): obj is AggregatedCart {
  const { isArray } = Array

  if (typeof obj !== 'object' || obj === null) return false
  if (!('carts' in obj)) return false
  if (typeof obj.carts !== 'object' || obj.carts === null) return false

  return Object.values(obj.carts).every((c: unknown) => {
    if (typeof c !== 'object' || c === null) return false
    if (!('cards' in c) || !('choiceCardsToUse' in c)) return false

    const isCart =
      isArray(c.cards) &&
      isArray(c.choiceCardsToUse) &&
      c.cards.every((card) => isCard(card)) &&
      c.choiceCardsToUse.every((choiceCard) => isChoiceCardItem(choiceCard))

    return isCart
  })
}

export const CartContextProvider: React.FC<PropsWithChildren<CartContextProvider>> = ({
  children,
  values: { cart: initialCart } = {
    cart: defaultAggregatedCart,
  },
}) => {
  const { mainKey } = useMainKeyContext()

  const [raw, setRaw] = useSavedState(getLocalStorageKey('cart'))
  const parsedCart = raw ? (JSON.parse(raw) as AggregatedCart) : {}
  const parsedOrDefault = isCart(parsedCart) ? parsedCart : initialCart
  const [aggregatedCart, setAggregatedCart] = useState<AggregatedCart>(parsedOrDefault)

  const saveCart = (cart?: AggregatedCart) => {
    if (cart) {
      setRaw(JSON.stringify(cart))
    } else {
      setRaw(JSON.stringify(aggregatedCart))
    }
  }

  useEffect(() => {
    function onBeforeUnload() {
      saveCart()
    }

    window.addEventListener('beforeunload', onBeforeUnload)

    return () => window.removeEventListener('beforeunload', onBeforeUnload)
  }, [saveCart])

  const addCard = (item: CartItem, cartId?: string) => {
    const newAggregatedCart = produce(aggregatedCart, (draft) => {
      const id = cartId ? cartId : mainKey
      if (!id) {
        console.warn('[Cart context] Cart not initialized')
        return
      }

      const currentCart = draft.carts[id] ?? defaultCart
      const cards = currentCart.cards
      const existingItemIndex = cards.findIndex(
        (card) =>
          card.id === item.id &&
          card.amount === item.amount &&
          card.feePaymentOption === item.feePaymentOption,
      )

      if (existingItemIndex === -1) {
        currentCart.cards.push(item)
        return
      }

      cards[existingItemIndex].quantity =
        Number(cards[existingItemIndex].quantity) + Number(item.quantity)
    })

    setAggregatedCart(newAggregatedCart)
    return newAggregatedCart
  }

  const addChoiceCard = (item: ChoiceCardItem, cartId?: string | null) => {
    const id = cartId ? cartId : mainKey
    if (!id) return

    const currentCart = aggregatedCart.carts[id] ?? defaultCart
    const newCart = produce(currentCart, (draft) => {
      const existingIndex = draft.choiceCardsToUse.findIndex(
        (v) => v.claim_code === item.claim_code,
      )
      if (existingIndex === -1) draft.choiceCardsToUse.push(item)
      else draft.choiceCardsToUse.splice(existingIndex, 1, item)
    })

    const newAggregatedCart = produce(aggregatedCart, (draft) => {
      draft.carts[id] = newCart
    })

    setAggregatedCart(newAggregatedCart)
    return newAggregatedCart
  }

  const setCart = (item: Cart, cartId?: string) => {
    const newAggregatedCart = produce(aggregatedCart, (draft) => {
      const id = cartId ? cartId : mainKey
      if (!id) return
      draft.carts[id] = item
    })

    setAggregatedCart(newAggregatedCart)
    return newAggregatedCart
  }

  const clearCart = useCallback(
    (cartId?: string) => {
      const newAggregatedCart = produce(aggregatedCart, (draft) => {
        const id = cartId ? cartId : mainKey
        if (!id) return
        if (draft.carts[id]) {
          const main = aggregatedCart.carts[id]?.choiceCardsToUse.find((el) => el.claim_code === id)
          const order = draft.carts[id]
          draft.carts[id] = {
            cards: defaultCart.cards,
            choiceCardsToUse: main ? [main] : [],
            orderId: (order && order.orderId) ?? null,
          }
        }
      })

      setAggregatedCart(newAggregatedCart)
    },
    [JSON.stringify(aggregatedCart)],
  )

  const checkIfChoiceCardBalancesUpToDate = async (cartId?: string): Promise<Maybe<boolean>> => {
    const id = cartId ? cartId : mainKey
    if (!id) return

    const selectedCart = aggregatedCart.carts[id]
    if (selectedCart) {
      for (const { claim_code, debit_value } of selectedCart.choiceCardsToUse) {
        try {
          const { balance } = await api.cardDetail(claim_code)
          if (balance !== debit_value) return true
        } catch (e) {
          console.warn(`[Cart] Unable to check balance of ${claim_code}. Reason: `, e)
        }

        return false
      }
    }
  }

  const removeCart = (cartId?: string) => {
    const newAggregatedCart = produce(aggregatedCart, (draft) => {
      const id = cartId ? cartId : mainKey
      if (!id) return
      if (draft.carts[id]) delete draft.carts[id]
    })

    setAggregatedCart(newAggregatedCart)
    return newAggregatedCart
  }

  const isMainChoiceCardEmpty = (): boolean => {
    if (!mainKey) return true
    const mainChoiceCardBalance =
      aggregatedCart.carts[mainKey]?.choiceCardsToUse.find(
        ({ claim_code }) => claim_code === mainKey,
      )?.debit_value ?? 0

    return mainChoiceCardBalance <= 0
  }

  const listCarts = () => aggregatedCart.carts

  const resetAggregatedCart = () => {
    setAggregatedCart(defaultAggregatedCart)
    return defaultAggregatedCart
  }

  const getOrderId = (cartId?: string) => {
    const id = cartId ? cartId : mainKey
    if (!id) return undefined
    const selectedCart = aggregatedCart.carts[id]
    if (selectedCart) return selectedCart.orderId
  }

  const setOrderId = (orderId?: string | null, cartId?: string) => {
    const newAggregatedCart = produce(aggregatedCart, (draft) => {
      const id = cartId ? cartId : mainKey
      if (!id) return
      const selectedCart = draft.carts[id]
      if (selectedCart) selectedCart.orderId = orderId
    })

    setAggregatedCart(newAggregatedCart)
    return newAggregatedCart
  }

  const updateCardsLanguage = (products: CatalogueProduct[], cartId?: string) => {
    const newAggregatedCart = produce(aggregatedCart, (draft) => {
      const id = cartId ? cartId : mainKey
      if (!id) return
      const selectedCart = draft.carts[id]
      if (!selectedCart) return
      selectedCart.cards = selectedCart.cards.map((card) => {
        const equivalent = products.find((product) => String(product.id) === card.id)
        const description = equivalent?.description_short
        const name = equivalent?.name

        if (description && name) {
          card.name = name
          card.description = description
        }

        return card
      })
    })

    setAggregatedCart(newAggregatedCart)
  }

  const getCurrentCart = (): Cart | undefined =>
    mainKey ? aggregatedCart.carts[mainKey] : undefined

  const getCurrentMainChoiceCard = () => {
    const cart = getCurrentCart()
    return cart?.choiceCardsToUse.find((choiceCard) => choiceCard.claim_code === mainKey)
  }

  const setMainChoiceCardBalance = (newBalance?: number | null, cartId?: string | null) => {
    const newAggregatedCart = produce(aggregatedCart, (draft) => {
      const id = cartId ? cartId : mainKey
      const isNewBalanceValid = typeof newBalance === 'number' && newBalance >= 0

      if (!id || !isNewBalanceValid) return

      const selectedCart = draft.carts[id]
      const mainChoiceCard = selectedCart?.choiceCardsToUse.find((el) => el.claim_code === id)

      if (!mainChoiceCard) return

      mainChoiceCard.debit_value = newBalance
    })

    setAggregatedCart(newAggregatedCart)
  }

  return (
    <CartContext.Provider
      value={{
        addCard,
        addChoiceCard,
        aggregatedCart,
        clearCart,
        getCurrentCart,
        getCurrentMainChoiceCard,
        getOrderId,
        isMainChoiceCardEmpty,
        listCarts,
        checkIfChoiceCardBalancesUpToDate,
        removeCart,
        resetAggregatedCart,
        saveCart,
        setAggregatedCart,
        setCart,
        setMainChoiceCardBalance,
        setOrderId,
        updateCardsLanguage,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
