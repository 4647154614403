import { Field, Form, Formik } from 'formik'
import { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as y from 'yup'

import { VectorsDict } from '@components/atoms/vectors/dict'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { Radio } from '@components/molecules/forms/inputs/Radio'
import { colours } from '@configs/colours'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { api } from '@services/api'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { AvailableSurveys } from '@constants/survey'
import { Textarea } from '@components/molecules/forms/inputs/Textarea'

type Props = { object_ref: string; type: AvailableSurveys }

export const Survey: React.FC<Props> = ({ object_ref, type }) => {
  const { t } = useTranslation()
  const [sent, setSent] = useState(false)
  const [error, setError] = useState(false)

  const initialValues = { score: '', text: '' }

  const sendSurvey = async ({ score, text }: typeof initialValues) => {
    try {
      setSent(true)
      await api.shopSurvey(object_ref, +score, text, { type })
    } catch (e) {
      console.error('Something went wrong sending the survey: ', e)
      setError(true)
    }
  }

  return (
    <Root>
      <Formik
        initialValues={initialValues}
        onSubmit={sendSurvey}
        validationSchema={y.object({
          score: y.string().required(),
          text: y.string().max(250, t('forms.validation.maxCharacters', { count: 250 })),
        })}
      >
        {({ isSubmitting }) => (
          <WithTranslateFormErrors>
            <Form>
              <Arrangement>
                <RadiosArrangement>
                  <StyledRadio
                    customToggler={(checked) => (
                      <CenterHorizontally>
                        <Suspense>
                          <VectorsDict.smileSad
                            fill={checked ? colours.error.red : colours.prisma.white}
                            stroke={checked ? colours.tomato[20] : colours.mist[200]}
                          />
                        </Suspense>
                      </CenterHorizontally>
                    )}
                    disabled={sent || error}
                    name="score"
                    value="1"
                  />
                  <StyledRadio
                    customToggler={(checked) => (
                      <CenterHorizontally>
                        <Suspense>
                          <VectorsDict.smileUnhappy
                            fill={checked ? colours.mandarin[100] : colours.prisma.white}
                            stroke={checked ? colours.mandarin[20] : colours.mist[200]}
                          />
                        </Suspense>
                      </CenterHorizontally>
                    )}
                    disabled={sent || error}
                    name="score"
                    value="2"
                  />
                  <StyledRadio
                    customToggler={(checked) => (
                      <CenterHorizontally>
                        <Suspense>
                          <VectorsDict.smileIndifferent
                            fill={checked ? colours.sunflower[100] : colours.prisma.white}
                            stroke={checked ? colours.sunflower[20] : colours.mist[200]}
                          />
                        </Suspense>
                      </CenterHorizontally>
                    )}
                    disabled={sent || error}
                    name="score"
                    value="3"
                  />
                  <StyledRadio
                    customToggler={(checked) => (
                      <CenterHorizontally>
                        <Suspense>
                          <VectorsDict.smileSmile
                            fill={checked ? colours.plant[100] : colours.prisma.white}
                            stroke={checked ? colours.plant[20] : colours.mist[200]}
                          />
                        </Suspense>
                      </CenterHorizontally>
                    )}
                    disabled={sent || error}
                    name="score"
                    value="4"
                  />
                  <StyledRadio
                    customToggler={(checked) => (
                      <CenterHorizontally>
                        <Suspense>
                          <VectorsDict.smileHappy
                            fill={checked ? colours.brand.blue : colours.prisma.white}
                            stroke={checked ? colours.blues[20] : colours.mist[200]}
                          />
                        </Suspense>
                      </CenterHorizontally>
                    )}
                    disabled={sent || error}
                    name="score"
                    value="5"
                  />
                </RadiosArrangement>
                <Field
                  name="text"
                  as={StyledTextarea}
                  description={t('forms.fields.addFeedback')}
                />
                <PrimaryButton
                  disabled={isSubmitting || sent || error}
                  iconRight={!sent ? Icons.PaperPlane : undefined}
                  style={{ width: 'fit-content' }}
                  type="submit"
                >
                  {error
                    ? t('somethingWentWrong')
                    : sent
                    ? t('thankYou')
                    : t('forms.actions.sendFeedback')}
                </PrimaryButton>
              </Arrangement>
            </Form>
          </WithTranslateFormErrors>
        )}
      </Formik>
    </Root>
  )
}

const StyledRadio = styled(Radio)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
`

const CenterHorizontally = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Arrangement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Root = styled.section`
  min-width: 304px;
  max-width: 420px;
  box-sizing: border-box;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`

const RadiosArrangement = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 16px;
`

const StyledTextarea = styled(Textarea)`
  margin-bottom: 24px;
  text-align: center;
`
