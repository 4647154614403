import { ButtonTextLinkCSS } from '@components/atoms/typography/css'
import { BodyLargeCSS } from '@components/atoms/typography/css/BodyLargeCSS'
import { BodyRegularCSS } from '@components/atoms/typography/css/BodyRegularCSS'
import { colours } from '@configs/colours'
import { useField } from 'formik'
import styled from 'styled-components'

type Props = React.HTMLProps<HTMLInputElement> & { name: string; description?: string }

export const Textarea: React.FC<Props> = ({ className, description, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <Label htmlFor="txtarea" className={className}>
      {description && <LabelText>{description}</LabelText>}
      <TextareaInput id="txtarea" {...field} {...meta} />
      {meta.error && <ErrorComponent>{meta.error}</ErrorComponent>}
    </Label>
  )
}

const ErrorComponent = styled.div`
  ${BodyRegularCSS};
  color: ${colours.error.red};
`

const TextareaInput = styled.textarea`
  ${BodyLargeCSS};
  border: 1px solid ${colours.mist[500]};
  border-radius: 8px;
  padding: 8px;
  resize: vertical;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  min-height: 160px;
`

const Label = styled.label`
  width: 100%;
`

const LabelText = styled.div`
  ${ButtonTextLinkCSS};
  border-radius: 8px;
  padding: 2px 8px;
  width: fit-content;
  margin-bottom: 4px;
`
