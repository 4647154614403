import { styled } from 'styled-components'

import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'

import { CardStack } from '@components/molecules/CardStack/CardStack'
import { colours } from '@configs/colours'
import { useSettingsContext } from '@hooks/useSettings'
import { mediaQueries } from '@utils/mediaQueries'

type Props = {
  currency?: string
  debitValue?: string
  placeholderSlot?: boolean
  slotImage?: string | null
  $baseColor?: string | null
}

export const BrandLightHeader: React.FC<Props> = ({
  currency,
  debitValue,
  placeholderSlot,
  slotImage: propsSlotImage,
  $baseColor,
}) => {
  const { slotImage } = useSettingsContext()

  return (
    <Root $baseColor={$baseColor ?? colours.prisma.black}>
      <Foreground $baseColor={$baseColor ?? colours.prisma.black}>
        <CardStack
          currency={currency}
          debitValue={debitValue}
          placeholderSlot={placeholderSlot}
          slotImage={propsSlotImage ?? slotImage}
        />
      </Foreground>
    </Root>
  )
}

const Root = styled.div<{ $baseColor?: string | null }>`
  ${mediaQueries.to.breakpoint.mobileL} {
    display: none;
  }
  position: relative;
  width: 100%;
  background-color: ${colours.prisma.background};
  background: linear-gradient(355deg, rgba(255, 255, 255, 0.3) 5%, rgba(255, 255, 255, 0) 85%),
    linear-gradient(
      0deg,
      ${({ $baseColor }) => `#${$baseColor}`},
      ${({ $baseColor }) => `#${$baseColor}`}
    );
  background: linear-gradient(355deg, rgba(255, 255, 255, 0.3) 5%, rgba(255, 255, 255, 0) 85%),
    linear-gradient(
      0deg,
      ${({ $baseColor }) => `${$baseColor}`},
      ${({ $baseColor }) => `${$baseColor}`}
    );
`

const Foreground = styled(WidthBoundary)<{ $baseColor?: string | null }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > *:only-child {
    margin-left: auto;
    margin-right: auto;
  }
  ${mediaQueries.from.breakpoint.desktop} {
    height: 506px;
  }
  height: 417px;
`
