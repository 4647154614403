import React, { PropsWithChildren, Suspense } from 'react'

import styled from 'styled-components'

import { IconComponents } from '@components/atoms/icons/IconComponents'
import { Fonts } from '@components/atoms/typography/Fonts'
import { colours } from '@configs/colours'
import { Icons } from '@typeDeclarations/components/atoms/icons'

type PrimaryButtonProps = { iconLeft?: Icons; iconRight?: Icons }

export const PrimaryButton: React.FC<
  PropsWithChildren<PrimaryButtonProps & React.ComponentProps<'button'>>
> = ({ children, iconLeft, iconRight, ...props }) => {
  const L = iconLeft ? IconComponents[iconLeft] : 'div'
  const R = iconRight ? IconComponents[iconRight] : 'div'

  return (
    <Suspense>
      <Root disabled={props.disabled} {...props}>
        {iconLeft && <L fill={colours.prisma.white} stroke={colours.prisma.white} />}
        {children && <Text style={{ gridArea: 'c' }}>{children}</Text>}
        {iconRight && <R fill={colours.prisma.white} stroke={colours.prisma.white} />}
      </Root>
    </Suspense>
  )
}

const Root = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  border: 0;
  border-radius: 12px;
  padding: 0 16px;

  height: 48px;
  width: 100%;

  background-color: ${colours.brand.dark};
  color: ${colours.prisma.white};

  &:hover {
    background-color: ${colours.mist[900]};
  }

  &:disabled {
    color: ${colours.mist[400]};
    background-color: ${colours.mist[100]};
    & svg,
    & path {
      stroke: ${colours.mist[400]};
    }
  }

  &:active:not(:disabled) {
    background-color: ${colours.prisma.black};
  }
`

const Text = styled(Fonts.ButtonCTALabelLarge)`
  white-space: nowrap;
`
