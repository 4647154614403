import { styled } from 'styled-components'

import { WhiteCard } from '@components/atoms/cards/WhiteCard'
import { Chip } from '@components/atoms/Chip/Chip'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { colours } from '@configs/colours'
import { zIndexes } from '@constants/zIndexes'

import { getCurrencySymbol } from '@utils/getCurrencySymbol'
import { getLocalisedPrice } from '@utils/getLocalisedPrice'
import { mediaQueries } from '@utils/mediaQueries'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction } from 'react'

type Props = {
  noBalance: boolean
  currency?: string
  slotImage?: string | null
  debitValue?: number
  error?: boolean
  setError?: Dispatch<SetStateAction<boolean>>
}

export const BrandMobileHeaderStateless: React.FC<Props> = ({
  currency,
  debitValue,
  noBalance,
  slotImage,
  error,
  setError,
}) => {
  const { i18n } = useTranslation()
  return (
    <Root>
      <Content>
        <StyledChip $noBalance={noBalance}>
          {noBalance
            ? getCurrencySymbol(currency) + ' - '
            : getLocalisedPrice(i18n.language, debitValue, currency)}
        </StyledChip>
        {slotImage && !error && (
          <SlotImage $faded={noBalance} onError={() => setError?.(true)} src={slotImage} />
        )}
        {(!slotImage || error) && <WhiteCard />}
      </Content>
    </Root>
  )
}

const Root = styled(WidthBoundary)`
  ${mediaQueries.from.breakpoint.mobileL} {
    display: none;
  }
  margin: 16px 0;
`

const Content = styled.div`
  position: relative;
`

const SlotImage = styled.img<{ $faded?: boolean }>`
  width: 100%;
  aspect-ratio: 1.6;
  outline: 4px solid rgba(255, 255, 255, 0.7);
  outline-offset: -4px;
  border-radius: 16px;
  opacity: ${(p) => (p.$faded ? 0.4 : 1)};
`

const StyledChip = styled(Chip)<{ $noBalance?: boolean }>`
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: ${(p) => (p.$noBalance ? colours.brand.purple : colours.prisma.black)};
  z-index: ${zIndexes.baseOnTop};
`
