import { useTranslation } from 'react-i18next'

import { useCartContext } from '@hooks/useCart'
import { useSettingsContext } from '@hooks/useSettings'

import { BrandHeaderStateless } from '@components/atoms/BrandHeaderStateless/BrandHeaderStateless'

type Props = { debit_value?: number | undefined; currency?: string | undefined }

export const BrandHeader: React.FC<Props> = ({
  debit_value: propsDebitValue,
  currency: propsCurrency,
}) => {
  const { colorBar, slotImage, shopBannerType, languageSpecifics } = useSettingsContext()
  const { i18n } = useTranslation()
  const { getCurrentMainChoiceCard } = useCartContext()
  const { debit_value: contextDebitValue, currency: contextCurrency } =
    getCurrentMainChoiceCard() ?? {}

  const debitValue =
    typeof propsCurrency !== 'undefined' && typeof propsDebitValue !== 'undefined'
      ? propsDebitValue
      : contextDebitValue
  const currency =
    typeof propsCurrency !== 'undefined' && typeof propsDebitValue !== 'undefined'
      ? propsCurrency
      : contextCurrency
  const shopText = languageSpecifics?.find((el) => el?.language === i18n.language)?.shop_text

  const noBalance =
    (typeof debitValue === 'number' || typeof debitValue === 'string') && Number(debitValue) === 0

  return (
    <BrandHeaderStateless
      noBalance={noBalance}
      shopText={shopText}
      colorBar={colorBar}
      currency={currency}
      slotImage={slotImage}
      shopBannerType={shopBannerType}
      debitValue={debitValue}
    />
  )
}
