import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { AboutFooter } from '@components/atoms/About/About'
import { GiftCardVertical } from '@components/atoms/cards/GiftCardVertical'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { ReferFriendIntrusion } from '@components/atoms/Panels/ReferFriendIntrusion'
import { Banner } from '@components/atoms/tooltips/Banners/Banner'
import { BrandHeader } from '@components/molecules/BrandHeader/BrandHeader'
import { BrandMobileHeader } from '@components/molecules/BrandMobileHeader/BrandMobileHeader'
import { BottomNav } from '@components/organisms/BottomNav/BottomNav'
import { useCartContext } from '@hooks/useCart'
import { useSettingsContext } from '@hooks/useSettings'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useMainKeyContext } from '@hooks/useMainKey'
import { Catalogue } from '@services/api.types'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { FeePaymentOptionEnum } from '@typeDeclarations/feePaymentOption'
import { mediaQueries } from '@utils/mediaQueries'
import { ReferFriendPanelStatus } from '@typeDeclarations/referFriend'
import { PanelBulidingIntrusion } from '@components/atoms/Panels/PanelBuldingIntrusion'

type Props = {
  productsLoading?: boolean
  productsErrorCode?: boolean | string | number
  products: Catalogue | null | undefined
}

export const CatalogueOrganism: React.FC<Props> = ({
  productsLoading,
  productsErrorCode,
  products,
}) => {
  const { i18n, t } = useTranslation()
  const { getCurrentMainChoiceCard } = useCartContext()
  const { panel } = useSettingsContext()
  const language = i18n.language
  const navigate = useNavigate()
  const { mainKey } = useMainKeyContext()
  const { hashFlows } = useHashFlowContext()

  const hasReferFriend = panel?.modules?.includes('refer_a_friend')
  const hasPanelBuilding = panel?.modules?.includes('panelbuilding')
  const referralProgramEnabled = panel?.status === ReferFriendPanelStatus.Active

  const showReferFriendBanner = !!referralProgramEnabled && !!hasReferFriend
  const showPanelBuildingBanner = !!referralProgramEnabled && !hasReferFriend && !!hasPanelBuilding

  const feePaymentOption = mainKey ? hashFlows[mainKey]?.feePaymentOption?.[0] : null
  const isFeePaymentOptionDeduct = feePaymentOption === FeePaymentOptionEnum.DeductFromCard
  const isFeePaymentOptionPay = feePaymentOption === FeePaymentOptionEnum.FeeViaPayment
  const noBalance = Number(getCurrentMainChoiceCard()?.debit_value) === 0

  return (
    <Root>
      <BrandHeader />
      <BrandMobileHeader />
      {noBalance && (
        <BannerWrapper>
          <Banner title={t('noBalanceInfo')} type="info">
            {t('noBalanceInfoDescription')}
          </Banner>
        </BannerWrapper>
      )}
      <Content>
        <WidthBoundary>
          {productsLoading && <Banner icon={Icons.Info} title={t('loading')} type="shop" />}
          {productsErrorCode && <Banner title={t('cannotLoadProducts')} type="error" />}
          {products?.content?.slice(0, 1).map((content) => {
            const languageSpecifics =
              content.language_specifics.find((el) => el.language === language) ??
              content.language_specifics.find((el) => el.language === 'en-GB') ??
              content.language_specifics[0]

            return !languageSpecifics.title?.trim() && <Spacer key="spacer" />
          })}
          {products?.content === null && (
            <>
              <Spacer />
              {showReferFriendBanner && (
                <Intrustion>
                  <ReferFriendIntrusion />
                </Intrustion>
              )}
              {showPanelBuildingBanner && (
                <Intrustion>
                  <PanelBulidingIntrusion />
                </Intrustion>
              )}
              <GridLayout>
                {products.products.map((item) => (
                  <GiftCardVertical
                    currency={item.currency}
                    description={item.description_short_wallet}
                    feeFlat={item.service_fee_flat}
                    feePercentage={item.service_fee_percent}
                    imageUrl={item.primary_image}
                    isFeeDeducted={isFeePaymentOptionDeduct}
                    isFeePaid={isFeePaymentOptionPay}
                    key={item.id}
                    onClick={() => navigate(`${item.id}`)}
                    title={item.name}
                  />
                ))}
              </GridLayout>
            </>
          )}
          {products?.content?.map((content, idx) => {
            const layout = content.view_type
            const languageSpecifics =
              content.language_specifics.find((el) => el.language === language) ??
              content.language_specifics.find((el) => el.language === 'en-GB') ??
              content.language_specifics[0]

            return layout === 'grid' ? (
              <React.Fragment key={content.language_specifics[0]?.description}>
                {languageSpecifics.title?.trim() && <Title>{languageSpecifics.title}</Title>}
                <GridLayout>
                  {!idx && !!showReferFriendBanner && (
                    <Intrustion>
                      <ReferFriendIntrusion />
                    </Intrustion>
                  )}
                  {!idx && !!showPanelBuildingBanner && (
                    <Intrustion>
                      <PanelBulidingIntrusion />
                    </Intrustion>
                  )}
                  {content.items.map((id) => {
                    const item = products.products.find((el) => el.id.toString() === id)
                    const feePercentage = item?.service_fee_percent
                    const feeFlat = item?.service_fee_flat

                    return item ? (
                      <GiftCardVertical
                        currency={item.currency}
                        description={item.description_short_wallet}
                        feeFlat={feeFlat}
                        feePercentage={feePercentage}
                        imageUrl={item.primary_image}
                        isFeeDeducted={isFeePaymentOptionDeduct}
                        isFeePaid={isFeePaymentOptionPay}
                        key={item.id}
                        onClick={() => navigate(id)}
                        title={item.name}
                      />
                    ) : null
                  })}
                </GridLayout>
              </React.Fragment>
            ) : (
              <React.Fragment key={content.language_specifics[0]?.description}>
                {languageSpecifics.title?.trim() && <Title>{languageSpecifics.title}</Title>}
                {!idx && !!showReferFriendBanner && (
                  <Intrustion>
                    <ReferFriendIntrusion />
                  </Intrustion>
                )}
                {!idx && !!showPanelBuildingBanner && (
                  <Intrustion>
                    <PanelBulidingIntrusion />
                  </Intrustion>
                )}
                <ScrollLayoutWrapper>
                  <ScrollLayout>
                    {content.items.reverse().map((id) => {
                      const item = products.products.find((el) => el.id.toString() === id)
                      const feePercentage = item?.service_fee_percent
                      const feeFlat = item?.service_fee_flat

                      return item ? (
                        <GiftCardWrapper key={item.id}>
                          <GiftCardVertical
                            currency={item.currency}
                            description={item.description_short_wallet}
                            feeFlat={feeFlat}
                            feePercentage={feePercentage}
                            imageUrl={item.primary_image}
                            isFeeDeducted={isFeePaymentOptionDeduct}
                            isFeePaid={isFeePaymentOptionPay}
                            key={item.id}
                            onClick={() => navigate(id)}
                            title={item.name}
                          />
                        </GiftCardWrapper>
                      ) : null
                    })}
                  </ScrollLayout>
                </ScrollLayoutWrapper>
              </React.Fragment>
            )
          })}
        </WidthBoundary>
      </Content>
      <AboutFooter />
      <BottomNav />
    </Root>
  )
}

const Intrustion = styled.div`
  width: 100%;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 1;
  grid-row-end: 2;
`

const Root = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 10;
`

const Spacer = styled.div`
  width: 100%;
  height: 32px;
`

const BannerWrapper = styled(WidthBoundary)`
  ${mediaQueries.from.breakpoint.mobileL} {
    transform: translateY(-50%);
  }
`

const GiftCardWrapper = styled.div`
  padding: 12px;

  &:first-child {
    padding: 12px 12px 12px 2px;
  }
`

const ScrollLayoutWrapper = styled.div`
  overflow-x: auto;

  ${mediaQueries.to.breakpoint.desktop} {
    padding: 0 32px 0 0;
  }
`

const ScrollLayout = styled.div`
  display: grid;
  grid-auto-flow: column;

  ${mediaQueries.from.breakpoint.mobile} {
    grid-auto-columns: calc(100% / 2);
  }

  ${mediaQueries.from.breakpoint.mobileL} {
    grid-auto-columns: calc(100% / 3);
  }

  ${mediaQueries.from.breakpoint.desktop} {
    grid-auto-columns: calc(100% / 4);
  }
`

const GridLayout = styled.div`
  display: grid;
  justify-items: center;
  gap: 16px;

  ${mediaQueries.from.breakpoint.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mediaQueries.from.breakpoint.mobileL} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${mediaQueries.from.breakpoint.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 24px;
  margin: 12px 0;
`

const Content = styled.div`
  flex-grow: 10;
  margin-bottom: 32px;
  margin-top: 24px;
`
