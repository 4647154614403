import { useTranslation } from 'react-i18next'
import { generatePath, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { AboutFooter } from '@components/atoms/About/About'
import { FromMobileL } from '@components/atoms/checkout/FromMobileL'
import { RootWithGradient } from '@components/atoms/checkout/Root'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { ReferLink } from '@components/atoms/ReferLink/ReferLink'
import { OrderOnItsWay } from '@components/molecules/checkout/OrderOnItsWay'
import { ReceiptSummarySection } from '@components/molecules/checkout/ReceiptSummarySection'
import { SummaryGridArrangement } from '@components/molecules/checkout/SummaryGridArrangement'
import { TitleWithProgressSection } from '@components/molecules/checkout/TitleWithProgressSection'
import { TopSection } from '@components/molecules/checkout/TopSection'
import { Survey } from '@components/organisms/Survey/Survey'
import { refer } from '@configs/urls'
import { OrderItem } from '@typeDeclarations/orderItem'
import { PaymentProcessingFee } from '@typeDeclarations/PaymentProcessingFee'
import { useSettingsContext } from '@hooks/useSettings'
import { ReferFriendPanelStatus } from '@typeDeclarations/referFriend'
import { MorePanels } from '@components/molecules/refer/MorePanels'
import { useFeatureFlag } from 'configcat-react'
import { useEffect, useState } from 'react'
import { JoinablePanel } from '@services/api.types'
import { api } from '@services/api'
import { useUserContext } from '@hooks/useUserContext'
import { useHashFlowContext } from '@hooks/useHashFlowContext'

type Props = {
  amountFromChoiceCards?: number
  balanceFromCards: number | undefined
  cardsRequireActivation?: boolean
  choiceCardsCount?: number
  currency?: string
  error: boolean | string
  items: OrderItem[]
  loading: boolean
  paymentProcessingFee?: PaymentProcessingFee
  priceFromCards: number | undefined
  reference?: string | null
}

export const OrderSummary: React.FC<Props> = ({
  amountFromChoiceCards,
  balanceFromCards,
  cardsRequireActivation,
  choiceCardsCount,
  currency,
  error,
  items,
  loading,
  paymentProcessingFee,
  priceFromCards,
  reference,
}) => {
  const { orderId = '' } = useParams()
  const { t } = useTranslation()
  const { panel, reloadShopConfig } = useSettingsContext()
  const { value: panelsProgramFlag } = useFeatureFlag('panelsProgram', false)
  const { email: userEmail } = useUserContext()
  const { hashFlows } = useHashFlowContext()

  const [joinablePanels, setJoinablePanels] = useState<JoinablePanel[]>()

  const mainKey = hashFlows?.[orderId]?.mainKey
  const prefillEmail = mainKey ? hashFlows[mainKey]?.prefillEmail : undefined
  const referralProgramEnabled = panel?.status === ReferFriendPanelStatus.Active
  const panelEmail = userEmail || prefillEmail

  const hasActiveReferral = panel?.status === ReferFriendPanelStatus.Active
  const hasPanelBuilding = panel?.modules?.includes('panelbuilding')

  useEffect(() => {
    if (mainKey) reloadShopConfig(mainKey)
    api.listPanels({ participantEmail: panelEmail }).then((r) => setJoinablePanels(r))
  }, [])

  return (
    <RootWithGradient>
      <FullHeight>
        <WidthBoundary>
          <TopSection asPlaceholder />
          <TitleWithProgressSection count={3} step={3} />
          <SummaryGridArrangement>
            <ContentContainer>
              <OrderOnItsWay
                cardsRequireActivation={cardsRequireActivation}
                error={error}
                loading={loading}
              />
              <div>
                {referralProgramEnabled && (
                  <ReferLink to={generatePath(refer.root)}>{t('doYouWantToReferFriend')}</ReferLink>
                )}
              </div>
              <Survey object_ref={orderId} type="shop_order" />
            </ContentContainer>
            <ReceiptArea>
              <ReceiptSummarySection
                amountFromChoiceCards={amountFromChoiceCards}
                balanceFromCards={balanceFromCards}
                cardsRequireActivation={cardsRequireActivation}
                choiceCardsCount={choiceCardsCount}
                currency={currency}
                items={items}
                pastTense
                paymentProcessingFee={paymentProcessingFee}
                priceFromCards={priceFromCards}
                reference={reference}
                tornAtTop
              />
            </ReceiptArea>
          </SummaryGridArrangement>
        </WidthBoundary>
        {!!hasPanelBuilding &&
          !!hasActiveReferral &&
          !!panelsProgramFlag &&
          !!panelEmail &&
          !!joinablePanels?.length && (
            <MorePanels
              title={t('wannaEarnSomethingYourself')}
              subtitle={t('whyNotJoinOtherPanels')}
              joinablePanels={joinablePanels}
              userData={{ email: panelEmail }}
            />
          )}
        <FromMobileL>
          <AboutFooter $noBackground />
        </FromMobileL>
      </FullHeight>
    </RootWithGradient>
  )
}

const FullHeight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
  position: relative;
  justify-content: space-between;
`

const ReceiptArea = styled.div`
  grid-area: receiptInfo;
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 48px;
  grid-area: 'orderInfo';
`
