import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { OrderSummary as OrderSummaryOrganism } from '@components/organisms/Checkout/OrderSummary'
import { datadogMessages } from '@constants/datadog'
import { OrderStatus } from '@constants/orderStatus'
import { datadogLogs } from '@datadog/browser-logs'

import { useCartContext } from '@hooks/useCart'
import { useMainKeyContext } from '@hooks/useMainKey'
import { api } from '@services/api'
import { ShopOrder, ShopOrderConfirmationTransaction } from '@services/api.types'
import { OrderItem } from '@typeDeclarations/orderItem'
import { PaymentProcessingFee } from '@typeDeclarations/PaymentProcessingFee'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { HashFlowActions } from '@typeDeclarations/hashFlowActions'
import { HashType } from '@typeDeclarations/hashType'

function groupAndSumProducts(shopOrder?: ShopOrder) {
  if (!shopOrder) return []

  const groupedProducts: Record<string, OrderItem> = {}

  for (const transaction of shopOrder.purchased_giftcards) {
    const productPk = transaction.gift_card.product.pk
    const productName = transaction.gift_card.product.name
    const currency = transaction.gift_card.product.currency
    const amount = Number(transaction.amount_used)
    const used_fee_value = Number(transaction.used_fee_value)
    const fee = Number(transaction.fee)
    const denominatedValues = transaction.gift_card.product.denominated_values

    const key = `${productPk}_${amount}_${denominatedValues}`

    if (key in groupedProducts) {
      const previousQuantity = groupedProducts[key].quantity ?? 0
      const currentQuantity = previousQuantity + 1
      groupedProducts[key].quantity = currentQuantity
    } else {
      const cardBalance = amount
      const cardPrice = amount + fee
      const calculatedAmount = used_fee_value + amount
      const data = {
        amount: calculatedAmount,
        cardBalance,
        cardPrice,
        fee,
        quantity: 1,
        title: productName || '',
      }

      groupedProducts[key] = data
      groupedProducts[key].currency = currency
    }
  }

  return Object.values(groupedProducts).sort((a, b) => {
    if (a.title && b.title) return a.title.localeCompare(b.title)
    return 0
  })
}

const RETRY_COUNT = 4
const RETRY_TIMEOUT = 3000

const Page: React.FC = () => {
  const { i18n } = useTranslation()
  const { orderId = '' } = useParams()
  const { removeCart, listCarts, saveCart } = useCartContext()
  const { mainKey, setMainKey } = useMainKeyContext()
  const { hashFlows, dispatchHashFlows } = useHashFlowContext()

  const [shopOrder, setShopOrder] = useState<ShopOrder>()
  const [dataLoading, setDataLoading] = useState(true)
  const [dataError, setDataError] = useState<false | string>(false)
  const retries = useRef(RETRY_COUNT)
  const paymentProcessingFee: PaymentProcessingFee | undefined =
    shopOrder?.payment?.payment_method === 'creditcard' && typeof shopOrder.payment_fee === 'string'
      ? { type: 'value', value: Number(shopOrder.payment_fee) }
      : undefined

  const pendingStatuses = useMemo(
    () => [
      OrderStatus.NEW,
      OrderStatus.WAITING_FOR_PAYMENT,
      OrderStatus.PAID,
      OrderStatus.ISSUING_CARDS,
    ],
    [],
  )

  const successStatuses = useMemo(
    () => [OrderStatus.SUCCESS, OrderStatus.ISSUING_CUSTOMER_READY],
    [],
  )
  const failedStatuses = useMemo(() => [OrderStatus.CANCELED, OrderStatus.FAILED], [])

  const orderFailed = !!(shopOrder?.status && failedStatuses.includes(shopOrder.status))

  useEffect(() => {
    const getShopOrder = () => {
      setDataLoading(true)
      setDataError(false)

      api
        .shopOrderDetail(orderId, i18n.language)
        .then((orderData) => {
          if (
            retries.current > 0 &&
            orderData.status &&
            pendingStatuses.includes(orderData.status)
          ) {
            retries.current = retries.current - 1
            setTimeout(() => getShopOrder(), RETRY_TIMEOUT)
            return
          }

          setShopOrder(orderData)
        })
        .catch((e: unknown) => {
          console.error(e)
          if (e instanceof Error) setDataError(e.name)
        })
        .finally(() => {
          setDataLoading(false)
        })
    }

    getShopOrder()
  }, [orderId, i18n.language, retries, pendingStatuses])

  useEffect(() => {
    const carts = listCarts()
    const paidCart = Object.entries(carts).find(([, cart]) => cart?.orderId === orderId)

    const orderHashFlow = mainKey ? hashFlows?.[mainKey] : undefined
    const shopFlow = orderHashFlow?.flow
    const prefillEmail = orderHashFlow?.prefillEmail

    if (!!mainKey && !!shopFlow) {
      dispatchHashFlows({
        type: HashFlowActions.SetHashFlow,
        payload: { hash: orderId, flow: shopFlow, type: HashType.OrderId, mainKey, prefillEmail },
      })
    }

    if (paidCart) {
      const cart = removeCart(paidCart[0])
      setMainKey(null)
      saveCart(cart)
    }
  }, [orderId, removeCart, listCarts, setMainKey, saveCart])

  useEffect(() => {
    const paymentAmount = !Number.isNaN(Number(shopOrder?.payment_amount))
      ? Number(shopOrder?.payment_amount)
      : 0

    if (shopOrder?.status && successStatuses.includes(shopOrder.status) && paymentAmount > 0) {
      datadogLogs.logger.info(datadogMessages.paymentSucceeded)
    }
  }, [shopOrder?.status, shopOrder?.payment_amount])

  const choiceCardsSumUsed = shopOrder?.used_giftcards.reduce(
    (acc: number, transaction: ShopOrderConfirmationTransaction) =>
      acc + Number(transaction.amount_used),
    0,
  )

  const items = groupAndSumProducts(shopOrder)
  const sumCardPrice = items
    .map((item) => Number(item.cardPrice) * Number(item.quantity))
    .reduce((a, c) => a + c, 0)
  const sumBalance = items
    .map((item) => Number(item.cardBalance) * Number(item.quantity))
    .reduce((a, c) => a + c, 0)

  const choiceCardsCount = shopOrder?.used_giftcards.length
  const cardsRequireActivation = shopOrder?.requires_activation

  return (
    <Root>
      <StyledForm>
        <OrderSummaryOrganism
          amountFromChoiceCards={choiceCardsSumUsed}
          balanceFromCards={sumBalance}
          cardsRequireActivation={cardsRequireActivation}
          choiceCardsCount={choiceCardsCount}
          currency={shopOrder?.currency}
          error={dataError || orderFailed}
          items={items}
          loading={dataLoading}
          paymentProcessingFee={paymentProcessingFee}
          priceFromCards={sumCardPrice}
          reference={shopOrder?.reference}
        />
      </StyledForm>
    </Root>
  )
}

const Root = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  height: 100%;
  width: 100%;
`

const StyledForm = styled.div`
  flex-grow: 10;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
`

export default Page
