import { OrderStatus } from '@constants/orderStatus'
import { PaymentStatus } from '@constants/paymentStatus'
import { FeePaymentOptions } from '@typeDeclarations/feePaymentOption'

export enum Flows {
  ShopGuest = 'shop_guest',
  ShopAccountMandatory = 'shop_account_mandatory',
  ShopAccountOptional = 'shop_account_optional',
}

export enum ShopBanner {
  Clean = 'clean',
  IconsRotated = 'icons_rotated',
  IconsVertical = 'icons_vertical',
  Text = 'text',
}

export type Product = {
  pk: string
  conditions_text: string
  currency: string
  default_image: string
  description_long: string
  description_short_wallet: string
  description_short: string
  expiration_text: string
  header_image: string
  max_purchase_qty: number | null
  name: string
  primary_image: string | null
  spending_text: string
  denominated_values: boolean | null | undefined
}

export type LanguageSpecificBranding = {
  language: string
  shop_text: string
  welcome_description: string
  welcome_title: string
  is_default: boolean
}

export type ShopPersonalization = null | {
  color_background: string | null // not used as for now
  color_bar: string | null
  image_logo: string | null
  image_welcome: string | null
  image_choicecard: string | null
  shop_banner: ShopBanner
  language_specifics: Maybe<Array<LanguageSpecificBranding>>
}

export type ReceiveConfig = {
  max_purchase_qty?: string | number | null
  max_purchase_value?: string | number | null
}

export type ProviderCard = {
  card_number?: string | null
  expiry_date?: string | null
  product_expiry?: string | null
  language?: string | null
  redeem_url?: string | null
  security_code?: string | null
}

export type Panel = {
  content: PanelContent
  modules: ('refer_a_friend' | 'panelbuilding')[]
  pk: string
  reward_currency: string
  reward_value: string
  status: string
}

export type ReceiveInfo = {
  balance?: number
  claim_code?: string
  config?: ReceiveConfig
  flow: Flows
  message?: string
  photo_url?: string
  prefill_email?: string
  product?: Partial<Product>
  shop_personalization?: ShopPersonalization
  thumbnail_url?: string
  video_url?: string
  language?: string
  shop_fee_payment_option?: null | FeePaymentOptions
  skip_claim_step?: boolean
  referral_code?: string | null
} & ProviderCard & { panel?: Panel }

export type ActivateInfo = {
  product?: Partial<Product>
  balance?: number
  is_expired?: boolean
} & Omit<ProviderCard, 'language'>

export type ClaimInfo = {
  claim_code_email_delay_minutes?: number | null
  claim_code: string | null
}

export type CheckCardFlow = {
  flow: Flows
  shop_personalization?: ShopPersonalization
  is_owned?: boolean
  claim_skipped?: boolean
}

export type ShopOrderBody = {
  communication_email: string
  payment_method: 'creditcard' | 'ideal' | '' | null
  payment_amount: number | null
  used_giftcards?: { claim_code?: string; debit_value?: number }[]
  primary_claim_code: string
  purchased_products?: { value?: number; amount?: number; product: string | number }[]
}

export type ShopOrderConfirmationGiftCard = {
  balance: string
  product: Partial<Product>
  expiry_date?: string
}
export type ShopOrderConfirmationTransaction = {
  status: string
  amount_used: string
  used_fee_value: string
  fee: string
  gift_card: ShopOrderConfirmationGiftCard
}

export type ShopOrder = {
  pk: string
  reference: string
  payment: null | {
    payment_url: string
    status?: PaymentStatus
    payment_method?: null | 'creditcard' | 'ideal'
  }
  status?: OrderStatus
  currency: string
  payment_amount: string
  used_giftcards: ShopOrderConfirmationTransaction[]
  purchased_giftcards: ShopOrderConfirmationTransaction[]
  requires_activation?: boolean
  payment_fee?: string | null
}

type LanguageSpecificContent = { title?: string; language: string; description: string }

export type Catalogue = {
  content: Array<CatalogueContent> | null
  products: Array<CatalogueProduct>
  language?: string
}

export type CatalogueContent = {
  details: boolean
  items: string[]
  language_specifics: LanguageSpecificContent[]
  view_type: 'horizontal_scroll' | 'grid'
}

export type CatalogueProduct = {
  affiliate_merchant_id: string
  affiliate_merchant_url: string
  conditions_text: string
  currency: 'EUR'
  default_image: null
  denominated_values?: boolean
  description_long: string
  description_short_wallet: string
  description_short: string
  disable_refresh: false
  expiration_text: string
  header_image: string
  id: number
  max_purchase_qty?: number | null
  max_value: string
  min_value: string
  name: string
  primary_image: string
  product_url: string
  service_fee_flat?: number | null
  service_fee_percent?: number | null
  spending_text: string
  url: string
  value_choices?: number[]
}

type PanelContent = {
  markdown_description?: string
  terms_and_conditions?: string
  text_description?: string
}

export type ReferralDetail = {
  referral_code: string
  panel: Panel
  panel_personalization: ShopPersonalization
}

export type ReferralSignUpData = { first_name: string; last_name: string; email: string }

export type JoinablePanel = {
  pk: string
  status: string
  panel_image?: string
  content: {
    markdown_description?: string
    terms_and_conditions?: string
    text_description?: string
    image_url?: string
  }
}

export type ParticipantData = {
  email: string
  first_name?: string
  last_name?: string
  phone_number?: string
  phone_area_code?: string
  panel: string
}

export type AfterJoinPanelResponse = {
  errors?: { error: string; panel: string }[]
  success?: { id: string }[]
  warnings?: { warning: 'panel_expired' | 'already_signed_up'; panel: string }[]
}

export type ListPanelsParams = { participantEmail?: string }

export type PersonalizationPreview = {
  color_bar?: string
  color_background?: string
  shop_banner?: 'icons_rotated'
  language_specifics?: LanguageSpecificBranding[] | null
  image_logo_url?: string
  image_welcome_url?: string
  image_choicecard_url?: string
  organization_logo_url?: string
  image_logo?: string
  image_welcome?: string
  image_choicecard?: string
}

export type ProductDefaultPreview = {
  primary_image?: string | null
}