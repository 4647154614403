import { useState } from 'react'

import { useCartContext } from '@hooks/useCart'
import { useSettingsContext } from '@hooks/useSettings'
import { BrandMobileHeaderStateless } from '@components/atoms/BrandMobileHeaderStateless/BrandMobileHeaderStateless'

export const BrandMobileHeader: React.FC = () => {
  const { slotImage } = useSettingsContext()
  const { getCurrentMainChoiceCard } = useCartContext()
  const { debit_value, currency } = getCurrentMainChoiceCard() ?? {}
  const [error, setError] = useState(false)

  const noBalance =
    (typeof debit_value === 'number' || typeof debit_value === 'string') &&
    Number(debit_value) === 0

  return (
    <BrandMobileHeaderStateless
      noBalance={noBalance}
      currency={currency}
      slotImage={slotImage}
      error={error}
      setError={setError}
      debitValue={debit_value}
    />
  )
}
