import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { envs } from '@configs/envs.ts'

import de from './locales/de/translation.json'
import da from './locales/da/translation.json'
import et from './locales/et/translation.json'
import enGB from './locales/en-GB/translation.json'
import enUS from './locales/en-US/translation.json'
import es from './locales/es/translation.json'
import fi from './locales/fi/translation.json'
import fr from './locales/fi/translation.json'
import it from './locales/it/translation.json'
import lt from './locales/lt/translation.json'
import lv from './locales/lv/translation.json'
import nl from './locales/nl/translation.json'
import no from './locales/no/translation.json'
import pl from './locales/pl/translation.json'
import sv from './locales/sv/translation.json'
import cs from './locales/cs/translation.json'
import hu from './locales/hu/translation.json'
import pt from './locales/pt/translation.json'
import ro from './locales/ro/translation.json'
import el from './locales/el/translation.json'

const resources = {
  'en-GB': { translation: enGB },
  'en-US': { translation: enUS },
  cs: { translation: cs },
  da: { translation: da },
  de: { translation: de },
  el: { translation: el },
  es: { translation: es },
  et: { translation: et },
  fi: { translation: fi },
  fr: { translation: fr },
  hu: { translation: hu },
  it: { translation: it },
  lt: { translation: lt },
  lv: { translation: lv },
  nl: { translation: nl },
  no: { translation: no },
  pl: { translation: pl },
  pt: { translation: pt },
  ro: { translation: ro },
  sv: { translation: sv },
}

export const flags = {
  'en-GB': '🇬🇧',
  'en-US': '🇺🇸',
  cs: '🇨🇿',
  da: '🇩🇰',
  de: '🇩🇪',
  el: '🇬🇷',
  es: '🇪🇸',
  et: '🇪🇪',
  fi: '🇫🇮',
  fr: '🇫🇷',
  hu: '🇭🇺',
  it: '🇮🇹',
  lt: '🇱🇹',
  lv: '🇱🇻',
  nl: '🇳🇱',
  no: '🇳🇴',
  pl: '🇵🇱',
  pt: '🇵🇹',
  ro: '🇷🇴',
  sv: '🇸🇪',
}

export const supportedLanguages = Object.keys(resources) as (keyof typeof resources)[]
const translationsAppId = envs.VITE_TRANSLATIONS_APP_ID
const translationsEndpoint = envs.VITE_TRANSLATIONS_ENDPOINT
const translationsPrefix = '[Translations]'

const backendOptions = {
  crossDomain: true,
  loadPath: (language: string, _namespace: string | string[]): string => language,
  request: async (
    _options: Record<string, unknown>,
    url: keyof typeof resources,
    _payload: undefined,
    callback: (err: unknown, res: { data: unknown; status: number }) => void,
  ) => {
    try {
      if (window.LOCAL_TRANSLATIONS)
        return callback(null, { status: 200, data: resources[url].translation })

      const raw = await fetch(`${translationsEndpoint}/${translationsAppId}/lang/${url}/`)
      const status = raw.status
      const data = await raw.json()

      return callback(null, { status, data })
    } catch (error) {
      console.error(
        `${translationsPrefix} Error getting translations from API. 
        This is likely due to poor network connection, CORS configuration or other connectivity problems rather than API itself`,
        error,
      )
      console.warn(`${translationsPrefix} Falling back to English translations from local source`)

      return callback(null, { status: 200, data: enGB })
    }
  },
}

const initOptions = {
  backend: backendOptions,
  debug: false,
  detection: { order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'] },
  fallbackLng: 'en-GB',
  interpolation: { escapeValue: false },
  resources,
  returnEmptyString: false,
  supportedLngs: Object.keys(resources),
}

const apiLoadedLanguages: Record<string, boolean> = {}

i18n.on('initialized', async () => {
  if (import.meta.env.TEST === 'true') return
  if (i18n.resolvedLanguage) {
    apiLoadedLanguages[i18n.resolvedLanguage] = true
    await i18n.reloadResources(i18n.resolvedLanguage)
    i18n.emit('languageChanged', i18n.resolvedLanguage)
  }
})

i18n.on('languageChanged', async (lang) => {
  if (import.meta.env.TEST === 'true') return
  if (!apiLoadedLanguages[lang]) {
    apiLoadedLanguages[lang] = true
    await i18n.reloadResources([lang])
    await i18n.changeLanguage()
  }
})

i18n.use(LanguageDetector).use(initReactI18next).use(HttpApi).init(initOptions)

export default i18n
