import styled from 'styled-components'

import { BodyRegularCSS } from '@components/atoms/typography/css/BodyRegularCSS'

import { mediaQueries } from '@utils/mediaQueries'

import { generatePath, useNavigate } from 'react-router-dom'

import { refer } from '@configs/urls'

import { Banner } from '../tooltips/Banners/Banner'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { useTranslation } from 'react-i18next'

export const PanelBulidingIntrusion: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Root>
      <Banner
        title={t('panelBuilding.title')}
        onCtaAction={() => navigate(generatePath(refer.root))}
        ctaText={t('panelBuilding.joinPanels')}
        icon={Icons.Gift}
      >
        {t('panelBuilding.description')}{' '}
      </Banner>
    </Root>
  )
}

const Root = styled.div`
  padding: 16px;
  ${BodyRegularCSS}
  ${mediaQueries.to.breakpoint.mobileL} {
    flex-direction: column;
  }
`
